import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {

  private username = new BehaviorSubject<any>(null);
  private type = new BehaviorSubject<any>('loader');
  private reset = new BehaviorSubject<any>(false);

  newUser(event) {
    this.username.next(event);
    this.reset.next(false);
  }

  newType(event) {
    this.type.next(event);
  }

  resetGrouping() {
    this.reset.next(true);
  }

  get user$ () {
    return this.username.asObservable();
  }

  get type$ () {
    return this.type.asObservable();
  }

  get reset$ () {
    return this.reset.asObservable();
  }

  constructor() { }
}
